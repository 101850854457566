import "./css/style.scss";
import Swiper, {Autoplay} from 'swiper';
import 'swiper/css';
import Vue from "vue";
import TopRowRectangles from "./vue/TopRowRectangles";

if (document.querySelector('.glowna') != null) {
    let app = new Vue({
        el: '.glowna',
        components: {
            TopRowRectangles
        }
    }) 
}

Swiper.use([Autoplay]);

const swiper = new Swiper('.swiper-container.partners', {
    speed: 500,
    loop: true,
    slidesPerView: 2,
    spaceBetween: 20,
    autoplay: {
        delay: 2000
    },

    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 0
        },
        1440: {
            slidesPerView: 5
        },
    }
});

function closeAltMenus() {
    document.querySelectorAll('.alt-menu').forEach(el => {
        el.classList.remove('open');
    })
}

function openAltMenu(name) {
    console.log(name);
    closeAltMenus();
    let menu = document.querySelector('.alt-menu[data-name="'+name+'"]');
    if (menu != null) {
        menu.classList.add('open');
    }

    return false;
}

function extractAltName(anchor) {
    let index = anchor.lastIndexOf("#");
    return anchor.substring(index+1);
}

window.addEventListener('DOMContentLoaded', ev => {
    document.body.addEventListener('click', ev => closeAltMenus());

    document.querySelectorAll('.strictMenu a').forEach(el => {
        el.addEventListener('click', ev => {
            ev.preventDefault();
            ev.stopPropagation();
            let anchor = ev.target.href;
            if (anchor.indexOf("#menu-") !== -1) {
                openAltMenu(extractAltName(anchor));
            } else {
                location.href = anchor;
            }
        })
    });

    document.getElementById('mobile-menu-toggle').addEventListener('click', ev => {
        let sidemenu = document.querySelector('.side-header');

        sidemenu.classList.toggle('open');
    })
})


function openSearch(el) {
    debugger
    el.classList.add('search-open');
    el.querySelector('input[name=s]').focus();
}

function closeSearch(el) {
    debugger
    el = el.closest('.search-wrap');
    el.classList.remove('search-open');
}

const search = document.querySelectorAll("li.search, .search-wrap");
search.forEach(el => {
    el.addEventListener("click", () => openSearch(el));
})

const searchform = document.querySelectorAll("input[name=s]");
searchform.forEach(el => {
    el.addEventListener("blur", () => closeSearch(el))
})

let seeMore = document.querySelector('.right-block .see-more');
if (seeMore) {
    seeMore.addEventListener("click", ev => {
        seeMore.classList.toggle("open");
        seeMore.nextElementSibling.classList.toggle("open");
    })
}