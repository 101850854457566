var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-bar" }, [
    _c(
      "div",
      { staticClass: "top-bar-content" },
      _vm._l(_vm.choosenItems, function(item, index) {
        return _c(
          "div",
          { key: "rect-box-" + index, class: "item s-" + index },
          [
            _c("a", {
              attrs: { href: item.link },
              domProps: { innerHTML: _vm._s(item.title) }
            })
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "top-bar-arrows" }, [
      this.itemsToShow > this.items.length
        ? _c("div", { staticClass: "next" }, [
            _c("img", {
              attrs: { src: _vm.assetUrl + "/img/prawo.png", alt: "" },
              on: {
                click: function($event) {
                  return _vm.modifyPointer(1)
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      this.itemsToShow > this.items.length
        ? _c("div", { staticClass: "prev" }, [
            _c("img", {
              attrs: { src: _vm.assetUrl + "/img/lewo.png", alt: "" },
              on: {
                click: function($event) {
                  return _vm.modifyPointer(-1)
                }
              }
            })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }