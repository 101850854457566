<template>
  <div class="top-bar">
        <div class="top-bar-content">
            <div 
                v-for="(item, index) in choosenItems" 
                :key="'rect-box-'+index" 
                :class="'item s-'+index"
            >
                <a :href="item.link" v-html="item.title"></a>
            </div>
        </div>
        <div class="top-bar-arrows">
            <div class="next" v-if="this.itemsToShow > this.items.length"><img :src="assetUrl + '/img/prawo.png'" alt="" @click="modifyPointer(1)"></div>
            <div class="separator"></div>
            <div class="prev"  v-if="this.itemsToShow > this.items.length"><img :src="assetUrl + '/img/lewo.png'" alt="" @click="modifyPointer(-1)"></div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            pointer: 0,
            itemsToShow: 6,
        };
    },
    props: {
        items: Array,
        assetUrl: String
    },
    methods: {
        modifyPointer: function (value) {
            this.pointer += value;

            if (this.pointer < 0) {
                this.pointer = 0;
            }

            if (this.pointer > this.maxPointer) {
                this.pointer = this.maxPointer;
            }
        }
    },
    computed: {
        choosenItems: function () {
            this.itemsToShow = Math.min(6, this.items.length);
            // debugger
            let start = this.pointer % this.items.length;
            let returnedArray = new Array();

            for (let index = 0; index < this.itemsToShow; index++) {
                returnedArray.push(this.items[(start + index) % this.items.length]);
            }

            return returnedArray;
        },
        maxPointer: function () {
            return this.items.length - this.itemsToShow;
        }
    }
}
</script>

<style lang="sass">
    .top-bar-arrows {
        .next, .prev {
            cursor: pointer;
            text-align: center;
        }
    }
</style>